import getRecaptchaToken from '@/utils/getRecaptchaToken'
import Api from '@/axios/api'

const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred, please try again later or contact our support'

function getErrorMessage (error) {
  return error?.response?.data?.at(0) || error?.response?.data || DEFAULT_ERROR_MESSAGE
}

async function signInAction ({ email, password }) {
  if (!email || !password) { return Promise.reject() }

  try {
    const recaptchaToken = await getRecaptchaToken()

    return Api.post('/customer/login', {
      email,
      password,
      recaptcha_token: recaptchaToken,
    })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)

        return Promise.reject(errorMessage)
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

async function signUpAction ({ firstName, lastName, email, password, company }) {
  if (!email || !password || !firstName || !lastName) { return Promise.reject() }

  try {
    const recaptchaToken = await getRecaptchaToken()

    return Api.post('/account/create', {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      company: company,
      recaptcha_token: recaptchaToken,
    })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)

        return Promise.reject(errorMessage)
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

async function signInWithSSOAction ({ email }) {
  if (!email) { return Promise.reject() }

  try {
    const recaptchaToken = await getRecaptchaToken()

    return Api.post('/customer/login-sso', {
      email,
      recaptcha_token: recaptchaToken,
    })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)

        return Promise.reject(errorMessage)
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

async function forgotPasswordAction ({ email }) {
  if (!email) { return Promise.reject() }

  try {
    const recaptchaToken = await getRecaptchaToken()

    return Api.post('/account/forgot-password', {
      email,
      recaptcha_token: recaptchaToken,
    })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)

        return Promise.reject(errorMessage)
      })
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  signInAction,
  signUpAction,
  signInWithSSOAction,
  forgotPasswordAction,
}
